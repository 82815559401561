<template>
    <div class="admin-rota-dates">
        <loading-screen :is-loading="isDeleting"></loading-screen>

        <!-- Page Header -->
        <div class="page-header">
            <h1 class="page-title">
                <a-button type="default" style="display: inline; margin-right: 15px"
                    @click.prevent="$router.push('/admin/responders/availability')" icon="left"></a-button>

                <admin-org-indicator></admin-org-indicator>

                Admin - Rota Dates
            </h1>
            <div class="actions">
                <a-button @click.prevent="loadRotaDates" :disabled="isLoading" class="button-margin-left btn-rounded"
                    icon="reload" size="large" type="default">Refresh</a-button>

                <a-button @click.prevent="createRotaDate" class="button-margin-left btn-rounded" icon="plus"
                    size="large" type="primary">Add</a-button>
            </div>
        </div>
        <!-- / Page Header -->

        <!-- Loading -->
        <div v-if="isLoading" class="loader">
            <a-spin></a-spin>
        </div>
        <!-- / Loading -->

        <!-- Loaded -->
        <div v-if="!isLoading">
            <!-- No results -->
            <a-alert v-if="!isLoading && rotaDates.length == 0" type="info" message="No rota dates to show">
            </a-alert>
            <!-- / No results -->

            <!-- Results-->
            <a-table v-if="rotaDates.length" :columns="columns" :data-source="rotaDatesForTable" :pagination="false"
                bordered>
                <div slot="actions" slot-scope="text, record">
                    <a-popconfirm title="Are you sure?" ok-text="Yes" cancel-text="No"
                        @confirm="deleteRotaDate(record)">
                        <a-button type="danger" icon="delete">Delete</a-button>
                    </a-popconfirm>
                </div>
            </a-table>
            <!-- / Results -->
        </div>
        <!-- / Loaded -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import rotaDates from "../../../api/rota-dates";
const _ = require('lodash');
import LoadingScreen from "../../../components/LoadingScreen.vue";
import moment from 'moment';

const COLUMNS = [
    {
        title: "Week",
        dataIndex: "week"
    },
    {
        title: "Example Date",
        dataIndex: "exampleDateHuman"
    },
    {
        title: "Actions",
        dataIndex: "actions",
        scopedSlots: { customRender: "actions" },
        width: 100,
    },
];

export default {
    data() {
        return {
            isLoadingRotaDates: false,
            rotaDates: [],

            isDeleting: false,

            columns: COLUMNS
        }
    },

    components: { LoadingScreen },

    computed: {
        ...mapGetters('admin', {
            tenantId: 'tenantId',
            selectedOrganisation: 'selectedOrganisation'
        }),

        isLoading() {
            return this.isLoadingRotaDates;
        },

        timetableWeeksAlias() {
            if (this.selectedOrganisation['content']['$v']['timetableWeeksAlias']) {
                return this.selectedOrganisation['content']['$v']['timetableWeeksAlias'].split(',');
            }
            return ['Week 1'];
        },

        rotaDatesForTable() {
            if (this.rotaDates && this.rotaDates.length) {
                let rotaDatesMapped = _.map(this.rotaDates, rotaDate => {
                    let week = this.timetableWeeksAlias[0];
                    let exampleDateHuman = moment(rotaDate.timetableStartDate).format('dddd Do MMMM Y');
                    return {
                        week,
                        exampleDateHuman,
                        ...rotaDate
                    }
                });
                return _.orderBy(rotaDatesMapped, 'timetableStartDate');
            }
            return [];
        }
    },

    created() {
        if (!this.selectedOrganisation) {
            this.$message.info("Please select an organisation");
            this.$router.push("/admin");
        } else {
            this.loadRotaDates();
        }
    },

    methods: {
        loadRotaDates() {
            let vm = this;
            vm.isLoadingRotaDates = true;
            rotaDates.getTimetableDatesForOrg(this.tenantId, this.selectedOrganisation.id).then(r => {
                vm.rotaDates = r.data;
                vm.isLoadingRotaDates = false;
            }).catch(e => {
                console.log(e);
                vm.isLoadingRotaDates = false;
                vm.rotaDates = [];
                vm.$message.error('Error loading rota dates. Please try again.');
            });
        },

        createRotaDate() {
            this.$router.push('/admin/rota-dates/create');
        },

        deleteRotaDate(rotaDate) {
            let vm = this;
            vm.isDeleting = true;
            rotaDates.deleteTimetableDate(
                this.tenantId,
                rotaDate.id
            ).then(() => {
                vm.isDeleting = false;
                vm.$message.success('Rota date deleted successfully');
                vm.loadRotaDates();
            }).catch(e => {
                console.log(e);
                vm.isDeleting = false;
                vm.$message.error('Error deleting rota date. Please try again.');
            });
        }
    }
}
</script>

<style lang="scss">
.admin-rota-dates {
    .loader {
        background: #fff;
        padding: 50px;
        text-align: center;
    }

    .ant-table {
        background: #fff !important;
    }

    padding-bottom: 50px;

    .button-margin-left {
        margin-left: 15px;
    }
}
</style>
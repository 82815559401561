import axios from 'axios';

export default {
    addTimetableDate(tenantId, params) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/TimetableDates', params);
    },

    getTimetableDatesForOrg(tenantId, orgId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/TimetableDates');
    },

    deleteTimetableDate(tenantId, timetableDateId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/TimetableDates/' + timetableDateId);
    },

    getTimetableWeekNumberForOrg(tenantId, orgId, zonedDateTime) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/TimetableDates/Now', zonedDateTime,
            {
                headers: {
                    "content-type": "application/json"
                }
            }
        );
    }
}